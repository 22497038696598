import request from '@/utils/request'

const tradeProjectMaterialAttachmentApi = {
  trade_project_material_attachment_list: '/trade/project_material_attachment/',
  trade_project_material_attachment_create: '/trade/project_material_attachment/',
  trade_project_material_attachment_update: '/trade/project_material_attachment/',
  trade_project_material_attachment_delete: '/trade/project_material_attachment/'
}

/**
 * 列表
 */
export function project_material_attachment_list (parameter) {
  return request({
    url: tradeProjectMaterialAttachmentApi.trade_project_material_attachment_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function project_material_attachment_create (parameter) {
  return request({
    url: tradeProjectMaterialAttachmentApi.trade_project_material_attachment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function project_material_attachment_update (parameter, project_material_attachment_id) {
  return request({
    url: tradeProjectMaterialAttachmentApi.trade_project_material_attachment_update + project_material_attachment_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function project_material_attachment_delete (project_material_attachment_id) {
  return request({
    url: tradeProjectMaterialAttachmentApi.trade_project_material_attachment_delete + project_material_attachment_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
